import unhead_Dip6soYMr2 from "/opt/render/project/src/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@22.5.4_bufferutil@4.0.8_eslint@9.10.0_jiti@1.21_k5aikvhttvngayptedg6vqzwcu/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_fPFTSPfSm0 from "/opt/render/project/src/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@22.5.4_bufferutil@4.0.8_eslint@9.10.0_jiti@1.21_k5aikvhttvngayptedg6vqzwcu/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import supabase_client_fq8AiAxK1A from "/opt/render/project/src/node_modules/.pnpm/@nuxtjs+supabase@1.4.0_bufferutil@4.0.8_utf-8-validate@5.0.10/node_modules/@nuxtjs/supabase/dist/runtime/plugins/supabase.client.js";
import payload_client_Crdz8TAFiR from "/opt/render/project/src/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@22.5.4_bufferutil@4.0.8_eslint@9.10.0_jiti@1.21_k5aikvhttvngayptedg6vqzwcu/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_UUxwtJH3Q2 from "/opt/render/project/src/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@22.5.4_bufferutil@4.0.8_eslint@9.10.0_jiti@1.21_k5aikvhttvngayptedg6vqzwcu/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_vhkPbYOArd from "/opt/render/project/src/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@22.5.4_bufferutil@4.0.8_eslint@9.10.0_jiti@1.21_k5aikvhttvngayptedg6vqzwcu/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_yg9UrWFquO from "/opt/render/project/src/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@22.5.4_bufferutil@4.0.8_eslint@9.10.0_jiti@1.21_k5aikvhttvngayptedg6vqzwcu/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_QM0ThcdciX from "/opt/render/project/src/node_modules/.pnpm/@pinia+nuxt@0.5.3_magicast@0.3.4_rollup@4.20.0_typescript@5.5.4_vue@3.5.6_typescript@5.5.4__webpack-sources@3.2.3/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/opt/render/project/src/apps/ui/.nuxt/components.plugin.mjs";
import prefetch_client_UZ1pzWVzoA from "/opt/render/project/src/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@22.5.4_bufferutil@4.0.8_eslint@9.10.0_jiti@1.21_k5aikvhttvngayptedg6vqzwcu/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import preview_client_PUZmZ3Me1B from "/opt/render/project/src/node_modules/.pnpm/@nuxthq+studio@1.1.2_bufferutil@4.0.8_magicast@0.3.4_rollup@4.20.0_utf-8-validate@5.0.10_webpack-sources@3.2.3/node_modules/@nuxthq/studio/dist/runtime/plugins/preview.client.mjs";
import slideovers_PVQby9xjAr from "/opt/render/project/src/node_modules/.pnpm/@nuxt+ui@2.18.4_axios@1.7.3_change-case@5.4.4_focus-trap@7.5.4_magicast@0.3.4_rollup@4.20.0_t_rnhywxt3zdqivtk7djxfkwg3qi/node_modules/@nuxt/ui/dist/runtime/plugins/slideovers.js";
import modals_P2umV2jaYx from "/opt/render/project/src/node_modules/.pnpm/@nuxt+ui@2.18.4_axios@1.7.3_change-case@5.4.4_focus-trap@7.5.4_magicast@0.3.4_rollup@4.20.0_t_rnhywxt3zdqivtk7djxfkwg3qi/node_modules/@nuxt/ui/dist/runtime/plugins/modals.js";
import colors_GSne3SeRAL from "/opt/render/project/src/node_modules/.pnpm/@nuxt+ui@2.18.4_axios@1.7.3_change-case@5.4.4_focus-trap@7.5.4_magicast@0.3.4_rollup@4.20.0_t_rnhywxt3zdqivtk7djxfkwg3qi/node_modules/@nuxt/ui/dist/runtime/plugins/colors.js";
import plugin_client_nECOsQSi1a from "/opt/render/project/src/node_modules/.pnpm/@nuxtjs+color-mode@3.4.2_magicast@0.3.4_rollup@4.20.0_webpack-sources@3.2.3/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import plugin_lxUhcdhcUF from "/opt/render/project/src/node_modules/.pnpm/@nuxt+icon@1.5.1_magicast@0.3.4_rollup@4.20.0_vite@5.4.3_@types+node@22.5.4_sass-embedded@1.7_wfynl54avxf2eoq6nfpshmawie/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import acheron_client_lqDhrNAz8l from "/opt/render/project/src/apps/ui/core/plugins/acheron.client.js";
import error_logging_client_hb7cDb8LLK from "/opt/render/project/src/apps/ui/core/plugins/error-logging.client.ts";
import feature_flags_client_yDiIZxtHJu from "/opt/render/project/src/apps/ui/core/plugins/feature-flags.client.ts";
import lottie_YCAtSIlAiw from "/opt/render/project/src/apps/ui/core/plugins/lottie.ts";
import primevue_MOhlnAFvlV from "/opt/render/project/src/apps/ui/core/plugins/primevue.js";
import sentry_client_4BqsDhRlQY from "/opt/render/project/src/apps/ui/core/plugins/sentry.client.ts";
export default [
  unhead_Dip6soYMr2,
  router_fPFTSPfSm0,
  supabase_client_fq8AiAxK1A,
  payload_client_Crdz8TAFiR,
  navigation_repaint_client_UUxwtJH3Q2,
  check_outdated_build_client_vhkPbYOArd,
  chunk_reload_client_yg9UrWFquO,
  plugin_vue3_QM0ThcdciX,
  components_plugin_KR1HBZs4kY,
  prefetch_client_UZ1pzWVzoA,
  preview_client_PUZmZ3Me1B,
  slideovers_PVQby9xjAr,
  modals_P2umV2jaYx,
  colors_GSne3SeRAL,
  plugin_client_nECOsQSi1a,
  plugin_lxUhcdhcUF,
  acheron_client_lqDhrNAz8l,
  error_logging_client_hb7cDb8LLK,
  feature_flags_client_yDiIZxtHJu,
  lottie_YCAtSIlAiw,
  primevue_MOhlnAFvlV,
  sentry_client_4BqsDhRlQY
]